

$gradient_main: var(--gradient-main);
$gradient_light: var(--gradient-light);

.progress {
    transition: width 0.5s;
    background-color: $gradient_main;

    z-index: 10;
    position: relative;
}
