@mixin setHeight($h: auto) {
  height: $h;
  min-height: $h;
}

@mixin setWidth($w: auto) {
  width: $w;
  min-width: $w;
}

@mixin setSize($size: auto) {
  @include setHeight($size);
  @include setWidth($size);
}

@mixin circle($diameter) {
  @include setSize($diameter);
  border-radius: calc(#{ $diameter } / 2);
}

@mixin flexCenter() {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@mixin my($value) {
  margin-top: $value;
  margin-bottom: $value;
}

@mixin mx($value) {
  margin-left: $value;
  margin-right: $value;
}

@mixin py($value) {
  padding-top: $value;
  padding-bottom: $value;
}

@mixin px($value) {
  padding-left: $value;
  padding-right: $value;
}
