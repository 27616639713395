
.bonus_block__icon .vs-button__content {
  padding: 1px;
  background-color: rgba(0, 0, 0, 0);
}

.bonus_block__icon {
  background-color: rgba(255, 255, 255, 0.5);
}

.bonus__icon::after {
  content: url("~@/assets/images/bonus.svg");
  color: black;
}

$gradient_main: var(--gradient-main);
$gradient_light: var(--gradient-light);

.bonus_img {
  background: $gradient_main;

}

.bonus__item {
  position: relative;
}

.bonus__item::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: $gradient_light;
  opacity: .2;
  content: "";
  border-radius: 0.75rem;
}