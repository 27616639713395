@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('~@/assets/fonts/Roboto-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('~@/assets/fonts/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('~@/assets/fonts/Roboto-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('~@/assets/fonts/Roboto-Black.ttf') format('ttf');
}