@import "base";

@media (min-width: 1280px) {
  .dash_img {
    height: 80%;
  }
}

.dash_img img {
  height: 100%;
  width: 100%;

}