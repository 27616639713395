p {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
}

.desc {
  font-size: 15px;
  line-height: 24px;
}
