@import "@/assets/scss/utils/index";




































































































.vs-input {
  width: 100%;
}

