@media (min-width: 1280px) {
    .max-w {
        width: 70%;
        max-width: 1600px;
        height: auto;
    }
}

.general_block {
    height: 80%;
}

#question {
    transition: opacity 0.25s linear;
}

.button_quiz:active {
    animation-name: blink;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}


.embedded-video-16-9 {
    width:  90vw;
    height: 50.625vw; /* 90*9/16 */
    margin-left: 5vw;
    margin-right: 5vw;
}

@media (min-width: 893px) {
    .embedded-video-16-9 {
        width: 45vw;
        height: 25.3125vw; /* 45*9/16 */
        margin-left: 2vw;
        margin-right: 2vw
    }
}