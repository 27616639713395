@import "utils/palette";
@import "fonts";
@import "typography-base";

html {
  line-height: 1.5em;
}

h1, h2, h3, h4, h5, * {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
}

h1, h2, h3, h4 {
  font-weight: 900;
}

h1 {
  font-size: 40px;
  line-height: 48px;
}

h2 {
  font-size: 34px;
  line-height: 42px;
}

h3 {
  font-size: 28px;
  line-height: 32px;
}

h4 {
  font-size: 18px;
  line-height: 24px;

}

span {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}


.red {
  color: $p-red
}

.blue {
  color: $p-blue
}

.yellow {
  color: $p-yellow;
}


.bg-red {
  background-color: $p-red
}

.bg-blue {
  background-color: $p-blue
}

.bg-yellow {
  background-color: $p-yellow;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-light {
  font-weight: 300;
}